export const ActionTypes = {
  SET_PRODUCTS: "SET_PRODUCTS",
  SET_CIUDADES: "SET_CIUDADES",
  SET_ZONA: "SET_ZONA",
  SET_PAIS: "SET_PAIS",
  SET_COMUNA: "SET_COMUNA",
  SET_USUARIO: "SET_USUARIO",
  SET_CARACTERISTICAS: "SET_CARACTERISTICAS",
  SET_OPERATION: "SET_OPERATION",
  SET_CORREDOR: "SET_CORREDOR",
  SET_MONEDA: "SET_MONEDA",
  SET_REGION: "SET_REGION",
  SET_CATEGORY_PROPERTY: "SET_CATEGORY_PROPERTY",
  SET_TIPO_PROPERTY: "SET_TIPO_PROPERTY",
  SET_DOCUMENTOS: "SET_DOCUMENTOS",
  SET_REQUEST: "SET_REQUEST",
  SELECTED_PRODUCT: "SELECTED_PRODUCT",
  REMOVE_SELECTED_PRODUCT: "REMOVE_SELECTED_PRODUCT",
};
