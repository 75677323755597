import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, IconButton, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { session } from "../../session/constants";
import {
  setProducts,
  setDocumentos,
} from "../../redux/actions/productsActions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import image from "../../../src/containers/img.png";
import BedIcon from "@mui/icons-material/Bed";
import ShowerIcon from "@mui/icons-material/Shower";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Modal from "@mui/material/Modal";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import AddIcon from "@mui/icons-material/Add";
import ViewDocuments from "../Modal/ViewDocuments";
import DescriptionIcon from "@mui/icons-material/Description";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ComponentProperties = (props) => {
  var handleSubmit = "";
  var fetchDocumentos = "";
  const [idUsuario, setIdUsuario] = useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [openDocuments, setDocuments] = useState(false);
  const [token, setToken] = useState(
    sessionStorage.getItem(session.SESSION_TOKEN)
  );
  const [file, setFile] = React.useState({
    opcion: 0,
    nombre_PDF: "",
    idArchivo: "",
    PDF: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const documentos = useSelector((state) => state.allProducts.documentos);
  const open = Boolean(anchorEl);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseDocuments = () => {
    setDocuments(false);
  };
  const handleOpenDocuments = () => {
    setDocuments(true);
  };
  const properties = useSelector((state) => state.allProducts.products);

  const dispatch = useDispatch();

  const fetchProducts = async () => {
    const response = await axios
      .get("http://back.rentti.cl/v1/property")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setProducts(response.data));
  };
  // const fetchDocumentos = async () => {
  //   const response = await axios
  //     .get("http://back.rentti.cl/v1/property/getPDF/67", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  //   dispatch(setDocumentos(response.data));
  // };

  useEffect(() => {
    fetchProducts();
    if (fetchDocumentos != "") {
      fetchDocumentos();
    }
  }, []);

  function handlePDF(e) {
    setFile((userValues) => ({
      ...userValues,
      [e.target.name]: e.target.value,
    }));
  }
  const subirPdf = (e) => {
    setFile((userValues) => ({
      ...userValues,
      PDF: e.target.files[0],
    }));
    console.log("Soy el PDF wi", e.target.files[0]);
  };

  const getUser = async (e) => {
    axios
      .get("http://back.rentti.cl/v1/auth/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setIdUsuario(res.data.id);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getUser();

  const renderList = properties.map((product) => {
    const {
      id,
      titulo,
      descripcion,
      valor,
      dormitorios,
      banios,
      construido_m2,
      iduser,
    } = product;
    handleSubmit = async (e) => {
      const dataFile = new FormData();
      dataFile.append("opcion", file.opcion);
      dataFile.append("nombre_PDF", file.nombre_PDF);
      dataFile.append("PDF", file.PDF);
      try {
        const response = await axios({
          method: "post",
          url: `http://back.rentti.cl/v1/property/uploadPDF/${id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: dataFile,
        });
        console.log(response);
        handleCloseModal();
      } catch (error) {
        console.log(error);
      }
    };

    fetchDocumentos = async () => {
      const response = await axios
        .get(`http://back.rentti.cl/v1/property/getPDF/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((error) => {
          console.error(error);
        });
      dispatch(setDocumentos(response.data));
    };
    return (
      <Grid container justifyContent="center" alignContent="center" spacing={3}>
        {iduser == idUsuario ? (
          <Card key={product.id}>
            <CardActionArea sx={{ width: "900px" }}>
              <Grid container>
                <Grid item xs={4}>
                  <CardMedia
                    component="img"
                    image={image}
                    key={image}
                    // alt={title}
                    className="image"
                  />
                </Grid>
                <Grid item xs={8}>
                  <Grid container mt={2}>
                    <Grid item xs={9}>
                      <Typography gutterBottom variant="h5" component="div">
                        {titulo}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Button
                            id="fade-button"
                            aria-controls={open ? "fade-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            variant="outlined"
                            color="success"
                          >
                            <AddIcon />
                          </Button>
                          <Menu
                            id="fade-menu"
                            MenuListProps={{
                              "aria-labelledby": "fade-button",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                          >
                            <MenuItem
                              onClick={handleOpenModal}
                              gutterBottom
                              sx={{
                                display: "block !important",
                                padding: "5px 10px !important",
                              }}
                            >
                              Subir archivo
                            </MenuItem>
                            <MenuItem
                              onClick={handleOpenDocuments}
                              gutterBottom
                              sx={{
                                display: "block !important",
                                padding: "5px 10px !important",
                              }}
                            >
                              Ver documentos
                            </MenuItem>
                          </Menu>
                        </Grid>
                        <Grid item xs={6}>
                          <IconButton>
                            <FavoriteBorderIcon style={{ color: "red" }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Link to={`/product/${id}`}>
                    <CardContent>
                      <Grid container mt={3}>
                        <Grid item xs={3} display="flex">
                          <AttachMoneyIcon />
                          {
                            <Typography
                              gutterBottom
                              variant="subtitle2"
                              component="div"
                            >
                              {valor}
                            </Typography>
                          }
                        </Grid>
                      </Grid>
                      <Typography
                        pr={5}
                        variant="subtitle2"
                        color="text.secondary"
                      >
                        {descripcion}
                      </Typography>
                      <Grid container mt={3}>
                        <Grid item xs={3} display="flex">
                          <BedIcon />
                          {
                            <Typography
                              gutterBottom
                              variant="subtitle2"
                              component="div"
                            >
                              {dormitorios}
                            </Typography>
                          }
                        </Grid>
                        <Grid item xs={3} display="flex">
                          <ShowerIcon />
                          {
                            <Typography
                              gutterBottom
                              variant="subtitle2"
                              component="div"
                            >
                              {banios}
                            </Typography>
                          }
                        </Grid>
                        <Grid item xs={3} display="flex">
                          <ApartmentIcon />
                          {
                            <Typography
                              gutterBottom
                              variant="subtitle2"
                              component="div"
                            >
                              {construido_m2 + " m²"}
                            </Typography>
                          }
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Link>
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
        ) : (
          ""
        )}
      </Grid>
    );
  });
  return (
    <>
      {renderList}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Subir Archivo
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={4} mt={1} className="item-center">
                <Typography variant="body1">Seleccionar Archivo: </Typography>
              </Grid>
              <Grid item xs={8} mb={1}>
                <Button variant="contained" component="label" fullWidth>
                  {/* <GetAppIcon style={{ marginRight: "12px" }} /> */}
                  <input
                    id="filePath"
                    type="file"
                    name="PDF"
                    onChange={subirPdf}
                    // onChange={(event) => {
                    //   setFieldValue("name", setNameFile(event));
                    // }}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4} mt={1} className="item-center">
                <Typography variant="body1">Nombre: </Typography>
              </Grid>
              <Grid item xs={8} mb={1}>
                <TextField
                  name="nombre_PDF"
                  fullWidth
                  placeholder="nombre archivo"
                  id="name"
                  type="text"
                  size="small"
                  onChange={handlePDF}
                />
              </Grid>
            </Grid>

            <Grid container mt={2} spacing={2}>
              <Grid item xs={3} className="item-center">
                <Button variant="outlined" fullWidth onClick={handleCloseModal}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={3} className="item-center">
                <Button variant="contained" onClick={handleSubmit} fullWidth>
                  Subir
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      {/* <ViewDocuments
        handleCloseModal={handleCloseDocuments}
        open={openDocuments}
        getUser={getUser}
      /> */}
      <Modal
        open={openDocuments}
        onClose={handleCloseDocuments}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" gutterBottom mb={3}>
            Mis Archivos
          </Typography>
          <Grid container>
            {documentos.map((item) => (
              <Grid item xs={4} mb={2}>
                <Grid container justifyContent="center">
                  <DescriptionIcon sx={{ fontSize: "50px" }} />
                </Grid>
                <Grid container justifyContent="center">
                  <Typography
                    textAlign="center"
                    id="modal-modal-title"
                    variant="subtitle"
                  >
                    {item.ruta.substr(19)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
export default ComponentProperties;
