import React, { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedProduct,
  removeSelectedProduct,
} from "../redux/actions/productsActions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import QuoteNow from "./Modal/QuoteNow";
import imagen from "./img.png";
import { session } from "../session/constants";
import Alert from '@mui/material/Alert';
import { Link } from "react-router-dom";

const ProductDetails = () => {
  const [open, setOpen] = React.useState(false);
  const idUsuario = sessionStorage.getItem(session.SESSION_ID_USER)
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { productId } = useParams();
  let product = useSelector((state) => state.product);
  const {
    id,
    direccion,
    titulo,
    descripcion,
    valor,
    fecha_renovacion,
    banios,
    dormitorios,
    terreno_m2,
    ciudad,
  } = product;
  const dispatch = useDispatch();
  const fetchProductDetail = async (id) => {
    const response = await axios
      .get(`http://back.rentti.cl/v1/property/oneProperty/${id}`)
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(selectedProduct(response.data));
  };
  useEffect(() => {
    if (productId && productId !== "") fetchProductDetail(productId);
    return () => {
      dispatch(removeSelectedProduct());
    };
  }, [productId]);
  return (
    <Grid container justifyContent="center" alignContent="center">
      {Object.keys(product).length === 0 ? (
        <div>
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        <Grid container item xs={10} mb={1}>
          {product.iduser == idUsuario ?
            <Alert severity="info">Usted es propietario de este inmueble</Alert>
            : ""
          }
          <Grid item xs={12}>
            <Card sx={{ boxShadow: "none" }}>
              <CardActionArea>
                <Grid container>
                  <Grid item xs={7}>
                    <CardMedia
                      component="img"
                      sx={{ minHeight: "450px" }}
                      image={imagen}
                      // alt={title}
                      className="image"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <CardContent>
                      <Grid mt={2}>
                        <Typography gutterBottom variant="h5" component="div">
                          {titulo}
                        </Typography>
                        <span>Desde</span>
                        <Typography variant="h5" color="text.secondary">
                          {valor}
                          <i className="dollar sign icon" />
                        </Typography>
                      </Grid>
                      <Grid mt={3}>
                        <Typography
                          gutterBottom
                          variant="subtitle2"
                          color="text.secondary"
                        >
                          <i className="external alternate icon" />
                          {terreno_m2} m2
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="subtitle2"
                          color="text.secondary"
                        >
                          <i className="map marker alternate icon" />
                          {direccion}, {ciudad}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="subtitle2"
                          color="text.secondary"
                        >
                          <i className="bath icon" />
                          {banios}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="subtitle2"
                          color="text.secondary"
                        >
                          <i className="bed icon" />
                          {dormitorios}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="subtitle2"
                          color="text.secondary"
                        >
                          {descripcion}
                        </Typography>
                        <Grid mt={1}>
                          {
                            product.iduser == idUsuario ?
                              <Link to={`/edit/${product.id}`}>
                                <Button variant="contained" color="secondary">Editar propiedad</Button>
                              </Link>
                              :
                              <Button onClick={handleOpen}>Cotizar ahora</Button>
                          }
                          {/* <div className="ui vertical animated button" tabIndex="0">
                            <div onClick={handleOpen} className="hidden content">
                              <i className="shop icon"></i>
                            </div>
                            <div  className="visible content" >Cotizar ahora</div>
                          </div> */}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid xs={12} mb={4} mt={3}>
            <Divider />
          </Grid>

          <Grid item xs={8} p={3}>
            <Typography variant="h5">Ubicación</Typography>
            <br />
            <Typography gutterBottom variant="h6" color="text.secondary">
              <i className="map marker alternate icon" />
              {direccion}, {ciudad}
            </Typography>
            <br />
            <br />
            <Typography variant="h5">Características</Typography>
            <br />
            <TableContainer component={Paper} sx={{ maxWidth: "70%" }}>
              <Table>
                <TableRow>
                  <TableCell variant="head">Terreno m2</TableCell>
                  <TableCell align="left">
                    <i className="external alternate icon" /> {terreno_m2}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Dormitorios</TableCell>
                  <TableCell>
                    <i className="bed icon" /> {dormitorios}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Baños</TableCell>
                  <TableCell>
                    <i className="bath icon" /> {banios}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Fecha de Renovación</TableCell>
                  <TableCell>{fecha_renovacion}</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
            <br />
            <br />
            <Typography variant="h5">Descripcion</Typography>
            <br />
            <Typography variant="body1" color="text.secondary">
              {descripcion}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ border: "1px solid #C8C8C8" }} p={3}>
            <Typography variant="h5">Consejos de seguridad</Typography>
            <br />
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText primary="Desde Renti_inmobiliaria.com, nunca te pediremos contraseñas, PIN o códigos de verificación a través de WhatsApp, teléfono, SMS o email." />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component="a" href="#simple-list">
                  <ListItemText primary="Verifica que el inmueble exista y desconfía si te dicen que necesitan vender o arrendar con urgencia." />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component="a" href="#simple-list">
                  <ListItemText primary="Solicita la mayor cantidad posible de información sobre el inmueble, así como fotos y/o videos para comprobar su veracidad." />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component="a" href="#simple-list">
                  <ListItemText primary="Sospecha si el precio te parece demasiado barato como para ser cierto." />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component="a" href="#simple-list">
                  <ListItemText primary="Sospecha si el precio te parece demasiado barato como para ser cierto." />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component="a" href="#simple-list">
                  <ListItemText primary="No uses servicios de pago anónimos para pagar, reservar o adelantar dinero sin haber visto el inmueble." />
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      )}
      <QuoteNow
        open={open}
        propsProduct={product}
        handleClose={handleClose}
        idpropiedad={id}
      />
    </Grid>
  );
};

export default ProductDetails;
