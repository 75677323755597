import React, { useEffect, useCallback, useMemo } from "react";
import { Button, Grid, MenuList, Typography } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

const Step2 = (props) => {
  const { stepThird, setStepThird } = props;

  function handleStepThird(e) {
    setStepThird((userValues) => ({
      ...userValues,
      [e.target.name]: e.target.value,
    }));
  }
  return (
    <Grid container justifyContent="center" spacing={2}>
      {/* <form onSubmit={handle}> */}
      <Grid item xs={12} md={6}>
        <Typography
          align="left"
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            color: "#3f51b5",
          }}
        >
          COMPLETA LAS CARACTERISTICAS DEL INMUEBLE
        </Typography>
        <Grid mb={3}>
          <Typography
            gutterBottom
            variant="caption"
            style={{
              fontSize: "12px",
              color: "#707071",
              marginBottom: "10px !important",
            }}
          >
            Tendras mejor ubicacion en los resultados de busqueda y los
            interesados tendran toda la informacion que necesitan
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Longitud
            </Typography>
            <OutlinedInput
              name="longitud"
              id="outlined-adornment-weight"
              size="small"
              fullWidth
              value={stepThird.longitud}
              onChange={handleStepThird}
              endAdornment={<InputAdornment position="end">m</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Latitud
            </Typography>
            <OutlinedInput
              name="latitud"
              id="outlined-adornment-weight"
              size="small"
              fullWidth
              value={stepThird.latitud}
              onChange={handleStepThird}
              endAdornment={<InputAdornment position="end">m</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Superficie total
            </Typography>
            <OutlinedInput
              name="superficie_terreno"
              id="outlined-adornment-weight"
              size="small"
              fullWidth
              value={stepThird.superficie_terreno}
              onChange={handleStepThird}
              endAdornment={<InputAdornment position="end">m²</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Superficie util
            </Typography>
            <OutlinedInput
              name="mt_utiles"
              id="outlined-adornment-weight"
              size="small"
              fullWidth
              value={stepThird.mt_utiles}
              onChange={handleStepThird}
              endAdornment={<InputAdornment position="end">m²</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Superficie habitacion
            </Typography>
            <OutlinedInput
              name="sup_hab"
              id="outlined-adornment-weight"
              size="small"
              fullWidth
              value={stepThird.sup_hab}
              onChange={handleStepThird}
              endAdornment={<InputAdornment position="end">m²</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Terraza
            </Typography>
            <OutlinedInput
              name="mts_terrazas"
              id="outlined-adornment-weight"
              size="small"
              fullWidth
              value={stepThird.mts_terrazas}
              onChange={handleStepThird}
              endAdornment={<InputAdornment position="end">m²</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Terreno total (m - m²)
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <OutlinedInput
                  name="mts_totales"
                  id="outlined-adornment-weight"
                  size="small"
                  fullWidth
                  value={stepThird.mts_totales}
                  onChange={handleStepThird}
                  endAdornment={
                    <InputAdornment position="end">m</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <OutlinedInput
                  name="mts2_totales"
                  id="outlined-adornment-weight"
                  size="small"
                  fullWidth
                  value={stepThird.mts2_totales}
                  onChange={handleStepThird}
                  endAdornment={
                    <InputAdornment position="end">m²</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Terreno construido (m - m²)
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <OutlinedInput
                  name="mts_construidos"
                  id="outlined-adornment-weight"
                  size="small"
                  fullWidth
                  value={stepThird.mts_construidos}
                  onChange={handleStepThird}
                  endAdornment={
                    <InputAdornment position="end">m</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <OutlinedInput
                  name="construido_m2"
                  id="outlined-adornment-weight"
                  size="small"
                  fullWidth
                  value={stepThird.construido_m2}
                  onChange={handleStepThird}
                  endAdornment={
                    <InputAdornment position="end">m²</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Longitud frente
            </Typography>
            <OutlinedInput
              name="longitud_frente"
              id="outlined-adornment-weight"
              size="small"
              fullWidth
              value={stepThird.longitud_frente}
              onChange={handleStepThird}
              endAdornment={<InputAdornment position="end">m</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Longitud fondo
            </Typography>
            <OutlinedInput
              name="longitud_fondo"
              id="outlined-adornment-weight"
              size="small"
              fullWidth
              value={stepThird.longitud_fondo}
              onChange={handleStepThird}
              endAdornment={<InputAdornment position="end">m</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* </form> */}
    </Grid>
  );
};

export default Step2;
