import { ActionTypes } from "../constants/action-types";
const intialState = {
  products: [],
  ciudades: [],
  documentos:[],
  zona:[],
  pais:[],
  usuario:[],
  caracteristica:[],
  property:[],
  operation:[],
  comuna:[],
  region:[],
  category:[],
  corredor:[],
  moneda:[],
  request:[],
};

export const productsReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_PRODUCTS:
      return { ...state, products: payload.detail };
    case ActionTypes.SET_CIUDADES:
      return { ...state, ciudades: payload.detail };
    case ActionTypes.SET_ZONA:
      return { ...state, zona: payload.detail };
    case ActionTypes.SET_PAIS:
      return { ...state, pais: payload.detail };
    case ActionTypes.SET_USUARIO:
      return { ...state, usuario: payload.detail };
    case ActionTypes.SET_CARACTERISTICAS:
      return { ...state, caracteristica: payload.detail };
    case ActionTypes.SET_TIPO_PROPERTY:
      return { ...state, property: payload.detail };
    case ActionTypes.SET_OPERATION:
      return { ...state, operation: payload.detail };
    case ActionTypes.SET_COMUNA:
      return { ...state, comuna: payload.detail };
    case ActionTypes.SET_REGION:
      return { ...state, region: payload.detail };
    case ActionTypes.SET_CATEGORY_PROPERTY:
        return { ...state, category: payload.detail };
    case ActionTypes.SET_CORREDOR:
        return { ...state, corredor: payload.detail };
    case ActionTypes.SET_DOCUMENTOS:
        return { ...state, documentos:payload };
    case ActionTypes.SET_REQUEST:
        return { ...state, request:payload };
    case ActionTypes.SET_MONEDA:
        return { ...state, moneda: payload.detail };
    default:
      return state;
  }
};

export const selectedProductsReducer = (state = {}, { type, payload }) => {
  console.log(type);
  switch (type) {
    case ActionTypes.SELECTED_PRODUCT:
      return { ...state, ...payload.detail.[0] };
    case ActionTypes.REMOVE_SELECTED_PRODUCT:
      return {};
    default:
      return state;
  }
};
