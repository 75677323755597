import React, { useEffect, useCallback, useMemo } from "react";
import { Button, Grid, MenuList, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import YearPicker from "react-year-picker";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { format } from "date-fns";
import {
  setCorredor,
  setOperations,
  setCategoryProperty,
} from "../../../redux/actions/productsActions";

const Step0 = (props) => {
  const { stepFirts, setStepFirts } = props;

  const [venta, setVenta] = React.useState("");
  const [checked, setChecked] = React.useState([true, false]);
  const [zonaState, setZonaState] = React.useState("");
  const [paisState, setPaisState] = React.useState("");
  const [paisType, setTypeState] = React.useState("");
  const [comunaState, setComunaState] = React.useState("");
  const [value, setValue] = React.useState("");
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  let category = useSelector((state) => state.allProducts.category);
  let corredor = useSelector((state) => state.allProducts.corredor);
  let operation = useSelector((state) => state.allProducts.operation);

  function handleStepFirts(e) {
    setStepFirts((userValues) => ({
      ...userValues,
      [e.target.name]: e.target.value,
    }));
  }
  const dispatch = useDispatch();
  const fetchCategory = async () => {
    const response = await axios
      .get("http://back.rentti.cl/v1/property/typeProperty")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setCategoryProperty(response.data));
  };
  const fetchOperation = async () => {
    const response = await axios
      .get("http://back.rentti.cl/v1/property/operation")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setOperations(response.data));
  };
  const fetchCorredor = async () => {
    const response = await axios
      .get("http://back.rentti.cl/v1/property/broker")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setCorredor(response.data));
  };

  useEffect(() => {
    fetchCategory();
    fetchOperation();
    fetchCorredor();
  }, []);


  const handleChangeYear = (date) => {
    console.log(date);
    setStepFirts((userValues) => ({
      ...userValues,
      anio_construccion: date,
    }));
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={5}>
        <Typography
          align="left"
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            color: "#3f51b5",
          }}
        >
          EMPIEZA DESCRIBIENDO EL INMUEBLE
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Elige el inmueble que quieres publicar
            </Typography>
            <Grid item container mt={2} ml={1} xs={12} spacing={2}>
              <RadioGroup
                row
                name="id_tipo_propiedad"
                defaultValue={stepFirts.id_tipo_propiedad}
                onChange={handleStepFirts}
              >
                {category.map((item) => (

                  <Grid marginY={1} item xs={12} md={4}>
                    <FormControlLabel
                      value={item.id}
                      control={<Radio />}
                      label={item.nombre}
                    />
                  </Grid>
                ))}
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid item xs={6} mt={1}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Corredor
            </Typography>

            <Box
              sx={{
                with: "100%",
                background: "#fff",
                borderColor: "#fff",
                marginRight: "5px",
              }}
            >
              <FormControl fullWidth>
                <Select
                  name="id_corredor"
                  displayEmpty
                  size="small"
                  defaultValue={stepFirts.id_corredor}
                  onChange={handleStepFirts}
                >
                  <MenuItem disabled>
                    <Typography style={{ color: "#707071" }}>
                      Seleccionar
                    </Typography>
                  </MenuItem>
                  {corredor.map((item) => (
                    <MenuItem
                      sx={{
                        display: "block !important",
                        padding: "5px 10px !important",
                      }}
                      key={item.id}
                      value={item.id}
                    >
                      {item.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} mt={1}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Operación
            </Typography>

            <Box
              sx={{
                with: "100%",
                background: "#fff",
                borderColor: "#fff",
                marginRight: "5px",
              }}
            >
              <FormControl fullWidth>
                <Select
                  name="id_operacion"
                  displayEmpty
                  size="small"
                  defaultValue={stepFirts.id_operacion}
                  onChange={handleStepFirts}
                >
                  <MenuItem disabled>
                    <Typography style={{ color: "#707071" }}>
                      Seleccionar
                    </Typography>
                  </MenuItem>
                  {operation.map((item) => (
                    <MenuItem
                      sx={{
                        display: "block !important",
                        padding: "5px 10px !important",
                      }}
                      key={item.id}
                      value={item.id}
                    >
                      {item.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Año construcción
            </Typography>
            <YearPicker onChange={handleChangeYear} />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Fecha Renovación
            </Typography>
            <TextField
              name="fecha_publicacion"
              id="outlined-number"
              fullWidth
              type="date"
              variant="outlined"
              size="small"
              defaultValue={new Date()}
              onChange={handleStepFirts}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Step0;
