import React, { useEffect, useCallback, useMemo } from "react";
import { Button, Grid, MenuList, Typography } from "@mui/material";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import InboxIcon from "@mui/icons-material/Inbox";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";

import {
  setCiudades,
  setPais,
  setZona,
  setProperty,
  setComuna,
  setRegion,
} from "../../../redux/actions/productsActions";

const Step1 = (props) => {
  const { stepSecond, setStepSecond, isEdit, edit } = props;

  const [venta, setVenta] = React.useState("");
  const [checked, setChecked] = React.useState([true, false]);
  const [zonaState, setZonaState] = React.useState("");
  const [paisState, setPaisState] = React.useState("");
  const [paisType, setTypeState] = React.useState("");
  const [comunaState, setComunaState] = React.useState("");
  const [value, setValue] = React.useState("");
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  let city = useSelector((state) => state.allProducts.ciudades);
  let zone = useSelector((state) => state.allProducts.zona);
  let pais = useSelector((state) => state.allProducts.pais);
  let comuna = useSelector((state) => state.allProducts.comuna);
  let region = useSelector((state) => state.allProducts.region);
  let typeProperty = useSelector((state) => state.allProducts.property);
  const dispatch = useDispatch();

  function handleStepSecond(e) {
    setStepSecond((userValues) => ({
      ...userValues,
      [e.target.name]: e.target.value,
    }));
  }

  const fetchCiudad = async () => {
    const response = await axios
      .get("http://back.rentti.cl/v1/property/city")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setCiudades(response.data));
  };

  const fetchZone = async () => {
    const response = await axios
      .get("http://back.rentti.cl/v1/property/zone")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setZona(response.data));
  };

  const fetchPais = async () => {
    const response = await axios
      .get("http://back.rentti.cl/v1/property/country")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setPais(response.data));
  };

  const fetchComuna = async () => {
    const response = await axios
      .get("http://back.rentti.cl/v1/property/district")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setComuna(response.data));
  };

  const fetchTypeProperty = async () => {
    const response = await axios
      .get("http://back.rentti.cl/v1/property/propertyNu")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setProperty(response.data));
  };
  const fetchRegion = async () => {
    const response = await axios
      .get("http://back.rentti.cl/v1/property/region")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setRegion(response.data));
  };

  const comunaValues = {
    options: comuna.map((option) => option.nombre),
  };
  useEffect(() => {
    fetchCiudad();
    fetchZone();
    fetchPais();
    fetchComuna();
    fetchRegion();
    fetchTypeProperty();
  }, []);

  const handleChange = (event) => {
    setZonaState(event.target.value);
  };

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} md={5}>
        <Grid container>
          <Grid item mb={2}>
            <Typography
              align="left"
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                color: "#3f51b5",
              }}
            >
              COMPLETA LA DIRECCION Y DETALLES DEL INMUEBLE
            </Typography>
          </Grid>
        </Grid>
        <Grid container mb={1}>
          <Grid item xs={12} md={12}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Título de Publicación
            </Typography>
            <TextField
              name="titulo"
              id="outlined-multiline-flexible"
              placeholder="Título"
              gutterBottom
              fullWidth
              size="small"
              value={stepSecond.titulo}
              onChange={handleStepSecond}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            variant="subtitle2"
            style={{ fontSize: "14px" }}
          >
            Cuál es la ubicación exacta del inmueble?
          </Typography>
          <Typography
            gutterBottom
            variant="caption"
            style={{ fontSize: "12px", color: "#707071" }}
          >
            La direccion se verá en tu publicación
          </Typography>
          <TextField
            name="direccion"
            id="outlined-multiline-flexible"
            placeholder="Dirección"
            gutterBottom
            fullWidth
            size="small"
            value={stepSecond.direccion}
            onChange={handleStepSecond}
          />
        </Grid>
        <Grid container>
          <Grid item xs={6} mt={1}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Tipo Inmueble
            </Typography>

            <Box
              sx={{
                with: "100%",
                background: "#fff",
                borderColor: "#fff",
                marginRight: "5px",
              }}
            >
              <FormControl fullWidth>
                <Select
                  name="id_tipo_nu"
                  displayEmpty
                  size="small"
                  // value={stepSecond.id_tipo_nu}
                  defaultValue={stepSecond.id_tipo_nu}
                  onChange={handleStepSecond}
                >
                  <MenuItem disabled>
                    <Typography style={{ color: "#707071" }}>
                      Seleccionar
                    </Typography>
                  </MenuItem>
                  {typeProperty.map((item) => (
                    <MenuItem
                      sx={{
                        display: "block !important",
                        padding: "5px 10px !important",
                      }}
                      value={item.id}
                    >
                      {item.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} mt={1}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              País
            </Typography>

            <Box
              sx={{
                with: "100%",
                background: "#fff",
                borderColor: "#fff",
                marginRight: "5px",
              }}
            >
              <FormControl fullWidth>
                <Select
                  name="id_pais"
                  displayEmpty
                  size="small"
                  defaultValue={stepSecond.id_pais}
                  onChange={handleStepSecond}
                >
                  <MenuItem disabled>
                    <Typography style={{ color: "#707071" }}>
                      Seleccionar
                    </Typography>
                  </MenuItem>
                  {pais.map((item) => (
                    <MenuItem
                      sx={{
                        display: "block !important",
                        padding: "5px 10px !important",
                      }}
                      key={item.id}
                      value={item.id}
                    >
                      {item.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} mt={1}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Ciudad
            </Typography>

            <Box
              sx={{
                with: "100%",
                background: "#fff",
                borderColor: "#fff",
                marginRight: "5px",
              }}
            >
              <FormControl fullWidth>
                <Select
                  name="id_ciudad"
                  displayEmpty
                  size="small"
                  defaultValue={stepSecond.id_ciudad}
                  onChange={handleStepSecond}
                >
                  <MenuItem disabled>
                    <Typography style={{ color: "#707071" }}>
                      Seleccionar
                    </Typography>
                  </MenuItem>
                  {city.map((item) => (
                    <MenuItem
                      sx={{
                        display: "block !important",
                        padding: "5px 10px !important",
                      }}
                      key={item.id}
                      value={item.id}
                    >
                      {item.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} mt={1}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Comuna
            </Typography>

            <Box
              sx={{
                with: "100%",
                background: "#fff",
                borderColor: "#fff",
                marginRight: "5px",
              }}
            >
              <FormControl fullWidth>
                <Select
                  name="id_comuna"
                  displayEmpty
                  size="small"
                  defaultValue={stepSecond.id_comuna}
                  onChange={handleStepSecond}
                >
                  <MenuItem disabled>
                    <Typography style={{ color: "#707071" }}>
                      Seleccionar
                    </Typography>
                  </MenuItem>
                  {comuna.map((item) => (
                    <MenuItem
                      sx={{
                        display: "block !important",
                        padding: "5px 10px !important",
                      }}
                      key={item.id}
                      value={item.id}
                    >
                      {item.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} mt={1}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Zona
            </Typography>

            <Box
              sx={{
                with: "100%",
                background: "#fff",
                borderColor: "#fff",
                marginRight: "5px",
              }}
            >
              <FormControl fullWidth>
                <Select
                  name="id_zona"
                  displayEmpty
                  size="small"
                  defaultValue={stepSecond.id_zona}
                  onChange={handleStepSecond}
                >
                  <MenuItem disabled>
                    <Typography style={{ color: "#707071" }}>
                      Seleccionar
                    </Typography>
                  </MenuItem>
                  {zone.map((item) => (
                    <MenuItem
                      sx={{
                        display: "block !important",
                        padding: "5px 10px !important",
                      }}
                      key={item.id}
                      value={item.id}
                    >
                      {item.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} mt={1}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Región
            </Typography>

            <Box
              sx={{
                with: "100%",
                background: "#fff",
                borderColor: "#fff",
                marginRight: "5px",
              }}
            >
              <FormControl fullWidth>
                <Select
                  name="id_region"
                  displayEmpty
                  size="small"
                  defaultValue={stepSecond.id_region}
                  onChange={handleStepSecond}
                >
                  <MenuItem disabled>
                    <Typography style={{ color: "#707071" }}>
                      Seleccionar
                    </Typography>
                  </MenuItem>
                  {region.map((item) => (
                    <MenuItem
                      sx={{
                        display: "block !important",
                        padding: "5px 10px !important",
                      }}
                      key={item.id}
                      value={item.id}
                    >
                      {item.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} mt={1}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Detalla el inmueble
            </Typography>
            <TextField
              name="descripcion"
              id="outlined-multiline-flexible"
              placeholder="Descripción"
              value={stepSecond.descripcion}
              onChange={handleStepSecond}
              gutterBottom
              rows={2}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Step1;
