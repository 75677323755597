import React, { useEffect, useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Grid, TextField, Typography } from "@mui/material";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { session } from "../../session/constants";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 350,
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Login(props) {
  const { open, handleClose, createUser } = props;

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const [openA, setOpenA] = React.useState(false);
  const [openE, setOpenE] = React.useState(false);
  const [token, setToken] = React.useState(
    sessionStorage.getItem(session.SESSION_TOKEN)
  );
  let history = useHistory();

  const handleClickA = () => {
    setOpenA(true);
  };

  const handleCloseA = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenA(false);
  };
  const handleClickE = () => {
    setOpenE(true);
  };

  const handleCloseE = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenE(false);
  };
  const handleIngresar = () => {
    // handleStatusButton();
    handleClose();
  };
  function handleEmail(e) {
    setValues((userValues) => ({
      ...userValues,
      email: e.target.value,
    }));
  }
  function handlePassword(e) {
    setValues((userValues) => ({
      ...userValues,
      password: e.target.value,
    }));
  }
  function loadLogin() {
    return new Promise(resolve => {
      setTimeout(() => {
        history.push("/");
        window.location.reload();
      }, 1000);
    });
  }
  const getUser = (tokenLogin) => {
    axios
      .get("http://back.rentti.cl/v1/auth/me", {
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
        },
      })
      .then((res) => {
        sessionStorage.setItem(session.SESSION_ID_USER, res.data.id);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleSubmit = async (e) => {
    // store the states in the form data
    e.preventDefault();
    try {
      // make axios post request
      const response = await axios
        .get(
          `http://back.rentti.cl/v1/auth/login?email=${values.email}&password=${values.password}`
        )
        .catch((err) => {
          console.log("Err: ", err);
        });
      // console.log("Soy la respuesta del logeo", response.data.access_token);
      if (response.status == 200) {        
        handleIngresar();
        handleClickA();
        sessionStorage.setItem(session.SESSION_VALUE, session.SESSION_ON);
        sessionStorage.setItem(session.SESSION_TOKEN, response.data.access_token);
        getUser(response.data.access_token);
        
        const result = await loadLogin();
        result();
        
      }
    } catch (error) {
      handleClickE();
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        //   style={{  }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form>
              <Typography
                textAlign="center"
                variant="h6"
                id="transition-modal-title"
              >
                Iniciar sesión o registrarse
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="email"
                    placeholder="Email"
                    size="small"
                    fullWidth
                    value={values.email}
                    onChange={handleEmail}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      size="small"
                      fullWidth
                      placeholder="Contraseña"
                      onChange={handlePassword}
                      // onChange={handleChange("password")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        mt={3}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={createUser}
                        //  className={classes.button}
                      >
                        Crear cuenta
                      </Button>
                    </Grid>

                    <Grid item xs={6}>
                      <Link to={`/propertier`}>
                        <Button
                          mt={3}
                          variant="contained"
                          color="primary"
                          fullWidth
                          type="submit"
                          onClick={handleSubmit}
                          //  className={classes.button}
                        >
                          Ingresar
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>
      <Snackbar open={openA} autoHideDuration={6000} onClose={handleCloseA}>
        <Alert onClose={handleCloseA} severity="success" sx={{ width: "100%" }}>
          Bienvenido
        </Alert>
      </Snackbar>
      <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
        <Alert onClose={handleCloseE} severity="warning" sx={{ width: "100%" }}>
          Contraseña y/o email incorrectos
        </Alert>
      </Snackbar>
    </div>
  );
}
