import React, { useEffect, useCallback, useMemo } from "react";
import { Button, Grid, MenuList, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

const Step3 = (props) => {
  const { stepFourth, setStepFourth, isEdit, edit } = props;
  // console.log("Soy props de stepTercero: ", stepFourth);

  function handleStepFourth(e) {
    setStepFourth((userValues) => ({
      ...userValues,
      [e.target.name]: e.target.value,
    }));
  }

  const [values, setValues] = React.useState({
    weight: "",
  });
  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} md={5}>
        <Typography
          align="left"
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            color: "#3f51b5",
          }}
        >
          OTRAS CARACTERISCAS
        </Typography>
        <Grid mb={3}>
          <Typography
            gutterBottom
            variant="caption"
            style={{
              fontSize: "12px",
              color: "#707071",
              marginBottom: "10px !important",
            }}
          >
            Agrega mas detalles del inmueble para que los interesados conozcan
            mejor
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              # Dormitorios
            </Typography>
            <TextField
              name="dormitorios"
              id="outlined-number"
              fullWidth
              type="number"
              value={stepFourth.dormitorios}
              onChange={handleStepFourth}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
              placeholder="0"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              # Baños
            </Typography>
            <TextField
              name="banios"
              id="outlined-number"
              fullWidth
              value={stepFourth.banios}
              onChange={handleStepFourth}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
              placeholder="0"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              # Bodegas
            </Typography>
            <TextField
              name="n_bodega"
              id="outlined-number"
              fullWidth
              value={stepFourth.n_bodega}
              onChange={handleStepFourth}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
              placeholder="0"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              # Etacionamiento
            </Typography>
            <TextField
              name="n_estacionamiento"
              id="outlined-number"
              fullWidth
              value={stepFourth.n_estacionamiento}
              onChange={handleStepFourth}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
              placeholder="0"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              # Pisos edificio
            </Typography>
            <TextField
              name="pisos_edificio"
              id="outlined-number"
              fullWidth
              value={stepFourth.pisos_edificio}
              onChange={handleStepFourth}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
              placeholder="0"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Tipo calefaccion
            </Typography>
            <TextField
              name="tipo_calefaccion"
              id="outlined-number"
              fullWidth
              value={stepFourth.tipo_calefaccion}
              onChange={handleStepFourth}
              placeholder="Escriba aqui"
              type="text"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Tipo piso
            </Typography>
            <TextField
              name="tipo_piso"
              id="outlined-number"
              fullWidth
              value={stepFourth.tipo_piso}
              onChange={handleStepFourth}
              placeholder="Escriba aqui"
              type="text"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Tipo techumbre
            </Typography>
            <TextField
              name="tipo_techumbre"
              id="outlined-number"
              fullWidth
              value={stepFourth.tipo_techumbre}
              onChange={handleStepFourth}
              placeholder="Escriba aqui"
              type="text"
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Step3;
