import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ProductComponent from "../../containers/ProductComponent";
import { Grid, Button, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Categorias from "../Categorias";
import { Link } from "react-router-dom";
import { session } from "../../../src/session/constants";
import { setProducts } from "../../redux/actions/productsActions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import image from "../../../src/containers/img.png";
import BedIcon from "@mui/icons-material/Bed";
import ShowerIcon from "@mui/icons-material/Shower";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ApiIcon from "@mui/icons-material/Api";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ComponentProperties from "./ComponentProperties";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import ViewRequest from "./ViewRequest";
const ViewPropertier = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container justifyContent="center" alignContent="center">
      {/* <ComponentProperties /> */}
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <TabContext value={value}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab
              label="Mis propiedades"
              value="1"
              sx={{ display: "block", width: "100%" }}
            />
            <Tab
              label="Solicitudes de Cotizaciones"
              value="2"
              sx={{ display: "block", width: "100%" }}
            />
          </Tabs>
          <TabPanel value="1">
            <Grid container justifyContent="center" alignContent="center">
              <ComponentProperties />
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <ViewRequest />
          </TabPanel>
        </TabContext>
      </Box>
    </Grid>
  );
};
export default ViewPropertier;
