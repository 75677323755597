import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import User from "./Modal/User";
import { useSelector } from "react-redux";
import ProductComponent from "./ProductComponent";
import PropertyPublish from "./Modal/PropertyPublish";
import Login from "./Modal/Login";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { session } from "../session/constants";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const sessionGet = sessionStorage.getItem(session.SESSION_VALUE);

const Header = (props) => {
  const [search, setSearch] = React.useState("");
  // const [stateButton, setStateButton] = React.useState("");
  // const [stateButtonProperty, setStateButtonProperty] =
  //   React.useState("statusButtonPr");
  // const [stateIconProf, setStateIconProf] = React.useState("iconState");
  const [open, setOpen] = React.useState(false);
  const [openProperty, setOpenProperty] = React.useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [anchorProf, setAnchorProf] = React.useState(null);
  const openProf = Boolean(anchorProf);
  let history = useHistory();

  // const handleStatusButton = () => {
  //   setStateButtonProperty("");
  //   setStateButton("state");
  //   setStateIconProf("");
  // };
  const handleClick = (event) => {
    setAnchorProf(event.currentTarget);

  };
  const handleSalir = (event) => {
    sessionStorage.setItem(session.SESSION_VALUE, session.SESSION_OFF);
    sessionStorage.setItem(session.SESSION_TOKEN, "");
    sessionStorage.setItem(session.SESSION_ID_USER, 0);
    history.push("/");
    window.location.reload();
  };
  const handleCloseProf = () => {
    setAnchorProf(null);
  };

  // const handleInitilize = () => {
  //   setStateButton("");
  //   setStateButtonProperty("statusButtonPr");
  //   setStateIconProf("iconState");
  // };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenProperty = () => {
    setOpenProperty(true);
  };
  const handleCloseProperty = () => {
    setOpenProperty(false);
  };
  const handleOpenLogin = () => {
    setOpenLogin(true);
  };
  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  //Metodo de busqueda
  const seacher = (e) => {
    setSearch(e.target.value);
    console.log(e.target.value);
  };
  //Metodo filtrado
  const products = useSelector((state) => state.allProducts.products);
  let results = [];
  if (!seacher) {
    results = products;
  } else {
    results = products.filter((dato) => dato.titulo);
  }
  const handleBack = () => {
    history.push("/");
  };
  const handleProperty = () => {
    history.push("/propertier");
  };

  return (
    <Box sx={{ flexGrow: 1 }} mb={3}>
      <AppBar position="static">
        <Toolbar>
          <Button
            variant="h6"
            noWrap
            onClick={() => handleBack()}
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            RENTI_INMOBILIARIA
          </Button>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              value={search}
              onChange={seacher}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <Box sx={{ flexGrow: 1 }} />
          {sessionGet == session.SESSION_ON ?
            <>
              <Box
                sx={{ with: "100%", background: "#fff", borderColor: "#fff" }}
                p={1}
              >
                <Button
                  onClick={() => handleBack()}
                // onClick={handleOpenProperty}
                // className={stateButtonProperty}
                // variant="contained"
                >
                  Principal
                </Button>
              </Box>
              <Box
                sx={{ with: "100%", background: "#fff", borderColor: "#fff" }}
                p={1}
              >
                <Button
                  onClick={handleProperty}
                // className={stateButtonProperty}
                // variant="contained"
                >
                  Mis Propiedades
                </Button>
              </Box>

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={openProf ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openProf ? "true" : undefined}
                    >
                      <Avatar sx={{ width: 32, height: 32 }}>
                        <Avatar />
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorProf}
                  id="account-menu"
                  open={openProf}
                  onClose={handleCloseProf}
                  onClick={handleCloseProf}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: 0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem>
                    <ListItemIcon>
                      <Logout fontSize="medium" />
                    </ListItemIcon>
                    <Typography variant="subtitle2" pr={3}>
                      <div onClick={handleSalir}>Salir</div>
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </>
            :
            <>
              <Button onClick={handleOpen}>
                Crear cuenta
              </Button>
              <Button onClick={handleOpenLogin} >
                Ingresar
              </Button>
              <Box
                sx={{ with: "100%", background: "#fff", borderColor: "#fff" }}
                p={1}
              >
                <Button
                  onClick={handleOpenProperty}
                  // className={stateButton}
                  variant="contained"
                >
                  Publicar propiedad
                </Button>
              </Box>
            </>
          }


        </Toolbar>
      </AppBar>

      <User open={open} handleClose={handleClose} login={handleOpenLogin} />
      <PropertyPublish
        createUser={handleOpen}
        open={openProperty}
        handleClose={handleCloseProperty}
        // handleStatusButton={handleStatusButton}
        // handleCloseLogin={handleCloseLogin}
        handleOpenLogin={handleOpenLogin}
      />
      <Login
        createUser={handleOpen}
        open={openLogin}
        handleClose={handleCloseLogin}
      // handleStatusButton={handleStatusButton}
      />
    </Box>
  );
};

export default Header;
