import React, { useEffect, useCallback, useMemo } from "react";
import { Button, Grid, MenuList, Typography } from "@mui/material";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import {
  setMoneda,
  setCaracteristicas,
} from "../../../redux/actions/productsActions";
import { useDispatch, useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";

const Step4 = (props) => {
  const { stepFifth, setStepFifth } = props;

  function handleStepFifth(e) {
    setStepFifth((userValues) => ({
      ...userValues,
      [e.target.name]: e.target.value,
    }));
  }
  function handleSwitch(e) {
    setStepFifth((userValues) => ({
      ...userValues,
      [e.target.name]: e.target.checked,
    }));
  }

  const [zonaMoneda, setMonedaState] = React.useState("");
  const [personName, setPersonName] = React.useState([]);
  let moneda = useSelector((state) => state.allProducts.moneda);
  let caracteristica = useSelector((state) => state.allProducts.caracteristica);

  const dispatch = useDispatch();

  const fetchMoneda = async () => {
    const response = await axios
      .get("http://back.rentti.cl/v1/property/currency")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setMoneda(response.data));
  };
  const fetchCaracteristica = async () => {
    const response = await axios
      .get("http://back.rentti.cl/v1/property/specificCharacteristics")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setCaracteristicas(response.data));
  };
  useEffect(() => {
    fetchMoneda();
    fetchCaracteristica();
  }, []);
  const handleChange = (event) => {
    setMonedaState(event.target.value);
  };
  console.log("estoy como loquitas: ", stepFifth.gastos_comunes + " wi")

  const subirImagen = (e) => {
    setStepFifth((userValues) => ({
      ...userValues,
      "img_referencial": e.target.files[0],
    }));
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} md={5}>
        <Typography
          align="left"
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            color: "#3f51b5",
          }}
        >
          DETALLES DEL INMUEBLE
        </Typography>
        <Grid mb={1}>
          <Typography
            gutterBottom
            variant="caption"
            style={{
              fontSize: "12px",
              color: "#707071",
              marginBottom: "10px !important",
            }}
          >
            Agrega mas detalles del inmueble para que los interesados conozcan
            mejor
          </Typography>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Moneda
            </Typography>
            <Box
              sx={{
                with: "100%",
                background: "#fff",
                borderColor: "#fff",
                marginRight: "5px",
              }}
            >
              <FormControl fullWidth>
                <Select
                  name="id_moneda"
                  displayEmpty
                  size="small"
                  // onChange={handleChange}
                  defaultValue={stepFifth.id_moneda}
                  onChange={handleStepFifth}
                >
                  <MenuItem disabled>
                    <Typography style={{ color: "#707071" }}>
                      Seleccionar
                    </Typography>
                  </MenuItem>
                  {moneda.map((item) => (
                    <MenuItem
                      sx={{
                        display: "block !important",
                        padding: "5px 10px !important",
                      }}
                      key={item.id}
                      value={item.id}
                    >
                      {item.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Valor
            </Typography>
            <TextField
              name="valor"
              id="outlined-number"
              fullWidth
              placeholder="Escriba aqui"
              type="text"
              variant="outlined"
              size="small"
              value={stepFifth.valor}
              onChange={handleStepFifth}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant="caption"
              style={{
                fontSize: "12px",
                color: "#707071",
                marginBottom: "10px !important",
              }}
            >
              Activa los servicios
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Gastos comunes
            </Typography>
            <FormControlLabel control={<Switch name="gastos_comunes" checked={stepFifth.gastos_comunes == "1" ? true : false} onChange={handleSwitch} />} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Salas de reuniones
            </Typography>
            <FormControlLabel control={<Switch name="sala_reuniones" checked={stepFifth.sala_reuniones == "1" ? true : false} onChange={handleSwitch} />} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Vista despejada
            </Typography>
            <FormControlLabel control={<Switch name="vista_depejada" checked={stepFifth.vista_depejada == "1" ? true : false} onChange={handleSwitch} />} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Garantia
            </Typography>
            <FormControlLabel control={<Switch name="garantia" checked={stepFifth.garantia == "1" ? true : false} onChange={handleSwitch} />} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Privados
            </Typography>
            <FormControlLabel control={<Switch name="privados" checked={stepFifth.privados == "1" ? true : false} onChange={handleSwitch} />} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Contribuciones
            </Typography>
            <FormControlLabel control={<Switch name="contribuciones" checked={stepFifth.contribuciones == "1" ? true : false} onChange={handleSwitch} />} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Colocacion
            </Typography>
            <FormControlLabel control={<Switch name="colocacion" checked={stepFifth.colocacion == "1" ? true : false} onChange={handleSwitch} />} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Orientación
            </Typography>
            <FormControlLabel control={<Switch name="orientacion" checked={stepFifth.orientacion == "1" ? true : false} onChange={handleSwitch} />} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Custodia
            </Typography>
            <FormControlLabel control={<Switch name="custodia" checked={stepFifth.custodia == "1" ? true : false} onChange={handleSwitch} />} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Letras
            </Typography>
            <FormControlLabel control={<Switch name="letras" checked={stepFifth.letras == "1" ? true : false} onChange={handleSwitch} />} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Secretaria
            </Typography>
            <FormControlLabel control={<Switch name="secretaria" checked={stepFifth.secretaria == "1" ? true : false} onChange={handleSwitch} />} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Administracion
            </Typography>
            <FormControlLabel control={<Switch name="administracion" checked={stepFifth.administracion == "1" ? true : false} onChange={handleSwitch} />} />
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            >
              Estacionamiento visitas
            </Typography>
            <FormControlLabel control={<Switch name="estacionamineto_visitas" checked={stepFifth.estacionamineto_visitas == "1" ? true : false} onChange={handleSwitch} />} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              gutterBottom
              variant="subtitle2"
              style={{ fontSize: "14px" }}
            ></Typography>
            <Button variant="contained" component="label">
              Subir imagen
              <input name="img_referencial" accept="image/*" multiple type="file" onChange={(e) => subirImagen(e)} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Step4;
