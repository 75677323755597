import React, { useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setProducts } from "../redux/actions/productsActions";
import ProductComponent from "./ProductComponent";
import { Slider } from "./Slider";
import { Grid } from "@mui/material";
import Filtro from "./Filtro";
import Categorias from "./Categorias";
import Carrucel from "./Carrucel";

const ProductPage = () => {
  // console.log("Products :", products);
  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Categorias />
      </Grid>
      <Grid item md={3}>
        <Filtro />
      </Grid>
      <Grid item md={9} justifyContent="left">
        <Grid container>
          <Grid item xs={11}>
            <Carrucel />
          </Grid>
          <Grid item xs={12} justifyContent="left">
            <ProductComponent />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductPage;
