import { ActionTypes } from "../constants/action-types";

export const setProducts = (products) => {
  return {
    type: ActionTypes.SET_PRODUCTS,
    payload: products,
  };
};
export const setCiudades = (ciudades) => {
  return {
    type: ActionTypes.SET_CIUDADES,
    payload: ciudades,
  };
};
export const setZona = (zona) => {
  return {
    type: ActionTypes.SET_ZONA,
    payload: zona,
  };
};

export const setPais = (pais) => {
  return {
    type: ActionTypes.SET_PAIS,
    payload: pais,
  };
};
export const setComuna = (comuna) => {
  return {
    type: ActionTypes.SET_COMUNA,
    payload: comuna,
  };
};
export const setRegion = (region) => {
  return {
    type: ActionTypes.SET_REGION,
    payload: region,
  };
};
export const setCategoryProperty = (categoryProperty) => {
  return {
    type: ActionTypes.SET_CATEGORY_PROPERTY,
    payload: categoryProperty,
  };
};
export const setUsuario = (usuario) => {
  return {
    type: ActionTypes.SET_USUARIO,
    payload: usuario,
  };
};
export const setCaracteristicas = (caracteristicas) => {
  return {
    type: ActionTypes.SET_CARACTERISTICAS,
    payload: caracteristicas,
  };
};
export const setOperations = (operation) => {
  return {
    type: ActionTypes.SET_OPERATION,
    payload: operation,
  };
};

export const setProperty = (typeProperty) => {
  return {
    type: ActionTypes.SET_TIPO_PROPERTY,
    payload: typeProperty,
  };
};
export const setCorredor = (corredor) => {
  return {
    type: ActionTypes.SET_CORREDOR,
    payload: corredor,
  };
};
export const setMoneda = (moneda) => {
  return {
    type: ActionTypes.SET_MONEDA,
    payload: moneda,
  };
};
export const setDocumentos = (documentos) => {
  return {
    type: ActionTypes.SET_DOCUMENTOS,
    payload: documentos,
  };
};
export const setRequest = (request) => {
  return {
    type: ActionTypes.SET_REQUEST,
    payload: request,
  };
};
export const selectedProduct = (product) => {
  return {
    type: ActionTypes.SELECTED_PRODUCT,
    payload: product,
  };
};

export const removeSelectedProduct = () => {
  return {
    type: ActionTypes.REMOVE_SELECTED_PRODUCT,
  };
};
