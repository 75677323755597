import React, { useEffect, useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Grid, TextField, Typography } from "@mui/material";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 300,
  },
}));

export default function PropertyPublish(props) {
  const {
    open,
    handleClose,
    createUser,
    handleOpenLogin
  } = props;
  const classes = useStyles();

  const handleSumbit=()=>{
    handleOpenLogin();
    handleClose();
  }
  const handleCreate=()=>{
    createUser();
    handleClose();
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        //   style={{  }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form>
              <Typography
                textAlign="center"
                variant="h6"
                id="transition-modal-title"
              >
                ¡Hola! Para publicar, ingresa a tu cuenta
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Button
                    mt={3}
                    variant="contained"
                    color="primary"
                    // type="submit"
                    fullWidth
                    // onClick={createUser}
                    onClick={handleCreate}
                    //  className={classes.button}
                  >
                    Crear Cuenta
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {/* <Link to={`/propertier`}> */}
                    <Button
                      mt={3}
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={handleSumbit}
                      //  className={classes.button}
                    >
                      Ingresar
                    </Button>
                  {/* </Link> */}
                </Grid>
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
