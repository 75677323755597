import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { session } from "../../session/constants";
import { setRequest } from "../../redux/actions/productsActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { Grid, Typography, Button, IconButton, TextField } from "@mui/material";
import BedIcon from "@mui/icons-material/Bed";
import ShowerIcon from "@mui/icons-material/Shower";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddIcon from "@mui/icons-material/Add";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PersonIcon from "@mui/icons-material/Person";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import NumbersIcon from "@mui/icons-material/Numbers";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import MessageIcon from "@mui/icons-material/Message";
import InboxIcon from "@mui/icons-material/Inbox";
const ViewRequest = (props) => {
  const [token, setToken] = useState(
    sessionStorage.getItem(session.SESSION_TOKEN)
  );
  const request = useSelector((state) => state.allProducts.request);
  console.log(request);
  const dispatch = useDispatch();

  const fetchRequest = async () => {
    const response = await axios
      .get("http://back.rentti.cl/v1/form", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        console.error(error);
      });
    dispatch(setRequest(response.data));
  };

  useEffect(() => {
    fetchRequest();
  }, []);

  const renderList = request.map((product) => {
    const { nombre, apellido, email, telefono, estado, mensaje, rut } = product;

    return (
      <Grid container justifyContent="center" alignContent="center" spacing={3}>
        <Grid item xs={4}>
          <Card>
            <CardActionArea>
              <Grid container>
                <Grid item xs={12}>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={1}>
                        <PersonIcon />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          gutterBottom
                          variant="subtitle2"
                          component="div"
                        >
                          {nombre + " " + apellido}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <NumbersIcon />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          gutterBottom
                          variant="subtitle2"
                          component="div"
                        >
                          {rut}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={1}>
                        <CallIcon />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          gutterBottom
                          variant="subtitle2"
                          component="div"
                        >
                          {telefono}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <AlternateEmailIcon />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          gutterBottom
                          variant="subtitle2"
                          component="div"
                        >
                          {email}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={1}>
                        <MessageIcon />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          gutterBottom
                          variant="subtitle2"
                          component="div"
                        >
                          {mensaje}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    );
  });
  return <>{renderList}</>;
};
export default ViewRequest;
