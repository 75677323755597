import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProductListing from "./containers/ProductListing";
import Header from "./containers/Header";
import "./App.css";
import ProductDetails from "./containers/ProductDetails";
import ViewPropertier from "./containers/Propertier/ViewPropertier";
import Publish from "./containers/Propertier/Publish/Publish";
import { session } from "../src/session/constants";

const sessionGet = sessionStorage.getItem(session.SESSION_VALUE);

function App() {
  if (sessionGet == session.SESSION_ON) {
  }

  return (
    <div className="App">
      <Router>
        <Header />
        {sessionGet == session.SESSION_ON ? (
          <Switch>
            <Route path="/publish" component={Publish} />
            <Route path="/edit/:idEdit" component={Publish} />
            <Route path="/" exact component={ProductListing} />

            <Route path="/product/:productId" component={ProductDetails} />
            <Route path="/propertier" component={ViewPropertier} />

            <Route>404 Not Found!</Route>
          </Switch>
        ) : (
          <Switch>
            <Route path="/publish" component={ProductListing} />
            <Route path="/edit/:idEdit" component={ProductListing} />
            <Route path="/" exact component={ProductListing} />
            <Route path="/product/:productId" component={ProductDetails} />
            <Route path="/propertier" component={ProductListing} />
          </Switch>
        )}
      </Router>
    </div>
  );
}

export default App;
